import React from 'react'
import testimage from "../images/workshopvenue.jpeg";


function PastJobsListingComponent(props) {
  return (
    <div className="past-jobs-listing-component">
        <div className="job-listing-container">
            <div className="job-title-container">
                {props.jobTitle}
            </div>
            <div className="date-container">
                <div className="title">
                    Date:
                </div>
                <div className="date">
                    {props.startDate} - {props.endDate}
                </div>
            </div>
        </div>
    </div>
  )
}

export default PastJobsListingComponent