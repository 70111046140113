import React from "react";
import { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Context } from "./App.jsx";
import axios from "axios";

function ListingItem(props) {
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);

  const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` },
  };

  const employerViewButton = (
    <div>
      <Link
        to={props.pastJob ? "/employer/review" : "/employer/view/candidates"}
        state={{ jobID: props.jobID }}
      >
        <button>{props.pastJob ? "Give Reviews" : "View Candidates"}</button>
      </Link>
    </div>
  );
  // axios
  //   .get(`http://localhost:4000/job/${props.jobID}/candidates`, config)
  //   .then((res) => {
  //     console.log(res);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });

  return (
    <div className="listing-item">
      <div className="left-side flex">
        <div className="logo-container">
          <img src={apiUrl + `/img/employers/logo/${props.logo}`} alt="" />
        </div>
        <div className="flex-column">
          <div className="sub-header">
            {props.companyName} - {props.jobTitle}
          </div>
          <div className="body-text">
            {props.date} | {props.startTime} - {props.endTime} | ${props.pay}/hr
          </div>
        </div>
      </div>

      <div className="middle">
        <div className="sub-header">{english ? "Location" : "地點"}</div>
        <div className="body-text">{props.location}</div>
      </div>

      <div className="right-side flex-column">
        {props.employee ? null : employerViewButton}
        <Link
          to={props.employee ? "/view/jobdetail" : "/employer/view/jobdetail"}
          state={props.jobID}
        >
          <button>{english ? "View" : "查看"}</button>
        </Link>
        <div className="test">
          <Link to="/employer/view/candidates" state={{ jobID: props.jobID }}>
            <button>TEST View Candidate</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ListingItem;
