import React, { useState } from "react";

// Language selection overlay component
const LanguageOverlay = ({
  languages,
  selectedLanguages,
  toggleLanguage,
  closeOverlay,
}) => (
  <div className="language-overlay">
    <div className="overlay-content">
      <h3>Select Languages</h3>
      {languages.map((language) => (
        <div key={language} className="language-option">
          <label htmlFor={language}> {language}</label>
          <input
            type="checkbox"
            id={language}
            checked={selectedLanguages.includes(language)}
            onChange={() => toggleLanguage(language)}
          />
        </div>
      ))}
      <button onClick={closeOverlay}>Close</button>
    </div>
  </div>
);

const AddOptionOverlay = ({
  options,
  selectedOptions,
  toggleOption,
  closeOverlay,
}) => (
  <div className="option-overlay">
    <div className="overlay-content">
      <h3>Select Options</h3>
      {options.map((option) => (
        <div key={option} className="option-item">
          <label htmlFor={option}>{option}</label>
          <input
            type="checkbox"
            id={option}
            checked={selectedOptions.includes(option)}
            onChange={() => toggleOption(option)}
          />
        </div>
      ))}
      <button onClick={closeOverlay}>Close</button>
    </div>
  </div>
);

const Step1 = ({ formData, setFormData, nextStep, prevStep }) => {
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const languages = ["English", "Mandarin", "Chinese"];

  const toggleOverlay = () => setOverlayVisible(!isOverlayVisible);

  const toggleLanguage = (language) => {
    setFormData((prevData) => {
      const updatedLanguages = prevData.languages.includes(language)
        ? prevData.languages.filter((lang) => lang !== language)
        : [...prevData.languages, language];
      return { ...prevData, languages: updatedLanguages };
    });
  };

  return (
    <div className={`step-component`}>
      <div className={`${isOverlayVisible ? "blur" : ""}`}>
        <div className="title">Give us your basic information</div>
        <div className="input-field-item">
          <input
            type="text"
            placeholder="First Name"
            value={formData.firstName}
            onChange={(e) =>
              setFormData({ ...formData, firstName: e.target.value })
            }
          />
        </div>
        <div className="input-field-item">
          <input
            type="text"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={(e) =>
              setFormData({ ...formData, lastName: e.target.value })
            }
          />
        </div>
        <div className="input-field-item">
          <input
            type="text"
            placeholder="Mobile Number"
            value={formData.number}
            onChange={(e) =>
              setFormData({ ...formData, number: e.target.value })
            }
          />
        </div>
        <div
          className="input-field-item language-toggle"
          onClick={toggleOverlay}
        >
          <div className="text">
            Languages: {formData.languages.join(", ") || "Select languages"}
          </div>
        </div>
        <div className="button-container">
          <button onClick={prevStep}>Prev</button>
          <button onClick={nextStep}>Next</button>
        </div>
      </div>
      {isOverlayVisible && (
        <LanguageOverlay
          languages={languages}
          selectedLanguages={formData.languages}
          toggleLanguage={toggleLanguage}
          closeOverlay={toggleOverlay}
        />
      )}
    </div>
  );
};

const Step2 = ({ formData, setFormData, nextStep, prevStep }) => {
  const [isPersonalityOverlayVisible, setPersonalityOverlayVisible] =
    useState(false);
  const [isTechnicalOverlayVisible, setTechnicalOverlayVisible] =
    useState(false);

  // State to hold selected personality and technical skills
  const [selectedPersonalityOptions, setSelectedPersonalityOptions] = useState(
    []
  );
  const [selectedTechnicalOptions, setSelectedTechnicalOptions] = useState([]);

  const rowOneDataEng = [
    "Listening to Others",
    "Adaptability",
    "Empathy",
    "Responsibility",
    "Systems Thinking",
    "Communication and Collaboration",
    "Patience",
    "Time Management",
    "Open-mindedness",
    "Compassion",
  ];

  const rowTwoDataEng = [
    "Data Entry",
    "Writing",
    "Presentation Creation",
    "File Management",
    "Baking",
    "Cleaning",
    "Childcare",
    "Customer Service",
  ];

  // Toggle function for personality overlay
  const togglePersonalityOverlay = () => {
    setTechnicalOverlayVisible(false); // Close technical overlay if open
    setPersonalityOverlayVisible((prev) => !prev);
  };

  // Toggle function for technical overlay
  const toggleTechnicalOverlay = () => {
    setPersonalityOverlayVisible(false); // Close personality overlay if open
    setTechnicalOverlayVisible((prev) => !prev);
  };

  // Toggle option for personality
  const togglePersonalityOption = (option) => {
    const totalSelected =
      selectedPersonalityOptions.length + selectedTechnicalOptions.length;

    if (totalSelected >= 5 && !selectedPersonalityOptions.includes(option)) {
      alert("You can only select a total of 5 options.");
      return;
    }

    setSelectedPersonalityOptions((prevSelected) => {
      if (prevSelected.includes(option)) {
        return prevSelected.filter((item) => item !== option);
      } else {
        return [...prevSelected, option];
      }
    });
  };

  // Toggle option for technical skills
  const toggleTechnicalOption = (option) => {
    const totalSelected =
      selectedPersonalityOptions.length + selectedTechnicalOptions.length;

    if (totalSelected >= 5 && !selectedTechnicalOptions.includes(option)) {
      alert("You can only select a total of 5 options.");
      return;
    }

    setSelectedTechnicalOptions((prevSelected) => {
      if (prevSelected.includes(option)) {
        return prevSelected.filter((item) => item !== option);
      } else {
        return [...prevSelected, option];
      }
    });
  };

  // Determine if .blur should be applied
  const isBlurred = isPersonalityOverlayVisible || isTechnicalOverlayVisible;

  return (
    <div className={`step-component ${isBlurred ? "blur" : ""}`}>
      <div className={`${isBlurred ? "blur" : ""}`}>
        <div className="title">Describe Yourself</div>
        <div className="tags-display">
          {selectedPersonalityOptions.map((option) => (
            <div key={option} className="tag">
              {option}
            </div>
          ))}
        </div>

        <div className="item-1">
          <div className="sub-title">Personality</div>
          <button onClick={togglePersonalityOverlay}>Add +</button>
        </div>

        <div className="item-2">
          <div className="sub-title">Technical Skills</div>
          <div className="tags-display">
            {selectedTechnicalOptions.map((option) => (
              <div key={option} className="tag">
                {option}
              </div>
            ))}
          </div>
          <button onClick={toggleTechnicalOverlay}>Add +</button>
        </div>

        <div className="button-container">
          <button onClick={prevStep}>Prev</button>
          <button onClick={nextStep}>Next</button>
        </div>
      </div>

      {isPersonalityOverlayVisible && (
        <AddOptionOverlay
          options={rowOneDataEng}
          selectedOptions={selectedPersonalityOptions}
          toggleOption={togglePersonalityOption}
          closeOverlay={togglePersonalityOverlay}
        />
      )}
      {isTechnicalOverlayVisible && (
        <AddOptionOverlay
          options={rowTwoDataEng}
          selectedOptions={selectedTechnicalOptions}
          toggleOption={toggleTechnicalOption}
          closeOverlay={toggleTechnicalOverlay}
        />
      )}
    </div>
  );
};
const AddJobOverlay = ({ newJob, setNewJob, addJob, closeOverlay }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewJob((prev) => ({ ...prev, [name]: value }));
  };

  const handleEmploymentTypeChange = (type) => {
    setNewJob((prev) => ({ ...prev, employmentType: type }));
  };

  return (
    <div className="past-job-overlay">
      <div className="overlay-content">
        <h3>Add New Job</h3>
        <div>
          <label>Employment Type:</label>
          <div className="employment-type-options">
            <label>
              <input
                type="checkbox"
                checked={newJob.employmentType === "Full Time"}
                onChange={() => handleEmploymentTypeChange("Full Time")}
              />
              Full Time
            </label>
            <label>
              <input
                type="checkbox"
                checked={newJob.employmentType === "Part Time"}
                onChange={() => handleEmploymentTypeChange("Part Time")}
              />
              Part Time
            </label>
            <label>
              <input
                type="checkbox"
                checked={newJob.employmentType === "Temp"}
                onChange={() => handleEmploymentTypeChange("Temp")}
              />
              Temp
            </label>
          </div>
        </div>
        <div>
          <label>Company Name:</label>
          <input
            type="text"
            name="companyName"
            value={newJob.companyName}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Job Position:</label>
          <input
            type="text"
            name="jobPosition"
            value={newJob.jobPosition}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Start Date:</label>
          <input
            type="month"
            name="startDate"
            value={newJob.startDate}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>End Date:</label>
          <input
            type="month"
            name="endDate"
            value={newJob.endDate}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Job Description:</label>
          <textarea
            name="jobDescription"
            value={newJob.jobDescription}
            onChange={handleInputChange}
          />
        </div>
        <button onClick={addJob}>Add Job</button>
        <button onClick={closeOverlay}>Close</button>
      </div>
    </div>
  );
};

const Step3 = ({ formData, setFormData, nextStep, prevStep }) => {
  const [newJob, setNewJob] = useState({
    employmentType: "",
    companyName: "",
    jobPosition: "",
    startDate: "",
    endDate: "",
    jobDescription: "",
  });
  const [isOverlayVisible, setOverlayVisible] = useState(false);

  const toggleOverlay = () => setOverlayVisible(!isOverlayVisible);

  const addJob = () => {
    const updatedJobs = [...formData.pastJob, newJob];
    setFormData((prevData) => ({ ...prevData, pastJob: updatedJobs }));
    setNewJob({
      employmentType: "",
      companyName: "",
      jobPosition: "",
      startDate: "",
      endDate: "",
      jobDescription: "",
    });
    setOverlayVisible(false); // Close the overlay after adding the job
  };

  return (
    <div className="step-component">
      <div className="title">Job Experiences</div>
      <button className="add-job-button" onClick={toggleOverlay}>
        Add Job +
      </button>
      {formData.pastJob.length > 0 ? (
        <div className="past-job-container">
          {formData.pastJob.map((job, index) => (
            <div key={index} className="past-job-entry">
              <h4>Job {index + 1}</h4>
              <div>
                <label>Employment Type: {job.employmentType}</label>
              </div>
              <div>
                <label>Company Name: {job.companyName}</label>
              </div>
              <div>
                <label>Job Position: {job.jobPosition}</label>
              </div>
              <div>
                <label>Start Date: {job.startDate}</label>
              </div>
              <div>
                <label>End Date: {job.endDate}</label>
              </div>
              <div>
                <label>Job Description: {job.jobDescription}</label>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>No job experiences added yet.</div>
      )}
      {isOverlayVisible && (
        <AddJobOverlay
          newJob={newJob}
          setNewJob={setNewJob}
          addJob={addJob}
          closeOverlay={toggleOverlay}
        />
      )}
      <div className="button-container">
        <button onClick={prevStep}>Prev</button>
        <button onClick={nextStep}>Next</button>
      </div>
    </div>
  );
};

const Step4 = ({ formData, setFormData, nextStep, prevStep }) => {
  const handleFileChange = (e, field) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        [field]: file,
      }));
    }
  };

  return (
    <div className="step-component">
      <div className="title">Profile Pic & CV</div>
      <div className="item-1">
        <div className="sub-title">Profile Pic</div>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, "profilePic")}
        />
      </div>
      <div className="item-2">
        <div className="sub-title">CV</div>
        <input
          type="file"
          accept=".pdf,.doc,.docx"
          onChange={(e) => handleFileChange(e, "cv")}
        />
      </div>
      <div className="button-container">
        <button onClick={prevStep}>Prev</button>
        <button onClick={nextStep}>Next</button>
      </div>
    </div>
  );
};

function SignUpEmployeeNew() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    number: "",
    languages: [],
    bestFive: [],
    pastJob: [],
    profilePic: null,
    cv: null,
  });
  const [currentStep, setCurrentStep] = useState(1);
  const nextStep = () => setCurrentStep((prev) => Math.min(prev + 1, 4));
  const prevStep = () => setCurrentStep((prev) => Math.max(prev - 1, 1));

  return (
    <div className="SignUpEmployeeNew">
      {currentStep === 1 && (
        <Step1
          nextStep={nextStep}
          formData={formData}
          setFormData={setFormData}
          prevStep={prevStep}
        />
      )}
      {currentStep === 2 && (
        <Step2
          nextStep={nextStep}
          formData={formData}
          setFormData={setFormData}
          prevStep={prevStep}
        />
      )}
      {currentStep === 3 && (
        <Step3
          nextStep={nextStep}
          formData={formData}
          setFormData={setFormData}
          prevStep={prevStep}
        />
      )}
      {currentStep === 4 && (
        <Step4
          nextStep={nextStep}
          formData={formData}
          setFormData={setFormData}
          prevStep={prevStep}
        />
      )}
    </div>
  );
}

export default SignUpEmployeeNew;
