import React from "react";
import { useState, useEffect, useContext, useRef } from "react";
import DescriptorComponent from "./DescriptorComponent.jsx";
import axios from "axios";
import { Context } from "./App.jsx";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faXmark } from "@fortawesome/free-solid-svg-icons";

import LanguageComponent from "./components/LanguageComponent.jsx";
import NavbarEmployer from "./components/NavbarEmployer.jsx";

function CreateWorkshopEmployer() {
  const { english, setEnglish, apiUrl, setApiUrl } = useContext(Context);
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState(null);
  const [businessName, setBusinessName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [address, setAddress] = useState("");
  const [logo, setLogo] = useState(null);
  const [preferredLocation, setPreferredLocation] = useState([]);
  const [finalData, setFinalData] = useState({
    eventName: "",
    date: "",
    startTime: "",
    endTime: "",
    district: "",
    language: "",
    fee: 0,
    vacancy: 0,
    description: "",
    highlight: [],
  });
  const [languages, setLanguages] = useState([]);

  const navigate = useNavigate();
  // Below is data from previous screen, their login details
  const location = useLocation();
  const { state } = location;

  // Configs below for axiox;
  const config = {
    headers: { Authorization: `Bearer ${localStorage.token}` },
  };
  const multiFormConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const tagRef = useRef(null);

  const fakeData = {
    eventName: "Return to work",
    date: "2024-07-03",
    startTime: "09:45",
    endTime: "11:45",
    district: "東區",
    address: "North Point",
    language: ["廣東話", "英語"],
    fee: 0,
    vacancy: 30,
    description: "introduce workshop and app flex ",
    tags: ["job finding", "interview workshop"],
  };

  const handleRemoveLocation = (e) => {
    setPreferredLocation([
      ...preferredLocation.filter((item) => item != e.target.value),
    ]);
  };

  const handleDistrict = (e) => {
    console.log(e.target.value);
    setPreferredLocation([e.target.value]);
    setFinalData((prev) => {
      return { ...prev, district: e.target.value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Activated Mouse Hover");
    console.log("This is the final Data from hover :", finalData);
  };

  const handleSubmitFinal = (e) => {
    e.preventDefault();
    axios
      .post(
        `${apiUrl}/workshop`,
        finalData,
        // fakeData,
        config
      )
      .then((res) => {
        console.log(finalData);
        console.log("POSTED WORK SUCCEDED");
        navigate("/home/employer");
      })
      .catch((err) => {
        console.log(finalData);
        console.log(fakeData);
        console.log(err);
        console.log(config, "Failed");
        alert(
          "Sign Up Failed, Please Check All the Fields are Inputted Correctly"
        );
      });
    // console.log(finalData);
    // console.log(fakeData)
  };
  // const handleCompanyName = (e) => {
  //   setFinalData((prev) => {
  //     return { ...prev, companyName: e.target.value };
  //   });
  // };

  const handleBenefits = (e) => {
    setFinalData((prev) => {
      return { ...prev, benefits: e.target.value };
    });
  };
  const handleAddress = (e) => {
    setFinalData((prev) => {
      return { ...prev, address: e.target.value };
    });
  };

  const handleDate = (e) => {
    setFinalData((prev) => {
      return { ...prev, date: e.target.value };
    });
  };

  const handleDescription = (e) => {
    setFinalData((prev) => {
      return { ...prev, description: e.target.value };
    });
  };

  const handleEndTime = (e) => {
    setFinalData((prev) => {
      return { ...prev, endTime: e.target.value };
    });
  };

  const handleEventName = (e) => {
    setFinalData((prev) => {
      return { ...prev, eventName: e.target.value };
    });
  };

  const handleFee = (e) => {
    setFinalData((prev) => {
      return { ...prev, fee: Number(e.target.value) };
    });
  };
  const handleRequirement = (e) => {
    setFinalData((prev) => {
      return { ...prev, requirement: e.target.value };
    });
  };
  const handleStartTime = (e) => {
    setFinalData((prev) => {
      return { ...prev, startTime: e.target.value };
    });
  };

  const handleVacancy = (e) => {
    setFinalData((prev) => {
      return { ...prev, vacancy: Number(e.target.value) };
    });
  };

  const handleTag = (e) => {
    setTag(e.target.value);
  };
  const clearInputValue = () => {
    setTag(null);
    tagRef.current.value = "";
  };

  const handleRemoveTag = (index) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1);
    setTags(updatedTags);
  };
  const addTag = (e) => {
    e.preventDefault();
    setTags([...tags, tag]);
    clearInputValue();
  };
  const handleLanguages2 = (e) => {
    if (languages.includes(e.target.value)) {
      console.log("Language already exists");
      document.getElementById("preferredLanguages").selectedIndex = 0;
    } else {
      setLanguages([...languages, e.target.value]);
      document.getElementById("preferredLanguages").selectedIndex = 0;
    }
  };

  const removeLanguages = (e) => {
    setLanguages([...languages.filter((item) => item != e.target.value)]);
  };

  useEffect(() => {
    setFinalData((prev) => {
      return { ...prev, language: languages };
    });
    setFinalData((prev) => {
      return { ...prev, highlight: tags };
    });
  }, [languages, tags]);

  return (
    <div>
      <LanguageComponent />
      <NavbarEmployer />
      <div className="create-profile-container">
        <div className="title header-2">
          {english ? "Create Workshop" : "新增工作坊"}
        </div>
        <form action="">
          <div className="grid-container">
            <div className="row-item">
              <label htmlFor="job-title">
                {english ? "Event Name" : "職位名稱"}
              </label>
              <input type="text" onChange={handleEventName} name="job-title" />
            </div>
            <div className="row-item">
              <label htmlFor="description">
                {english ? "Description" : "工作坊詳情"}
              </label>
              <input
                type="text"
                onChange={handleDescription}
                name="description"
              />
            </div>
            <div className="row-item">
              <label htmlFor="address">{english ? "Date" : "日期"}</label>
              <input type="date" onChange={handleDate} name="date" />
            </div>
            <div className="row-item">
              <label htmlFor="start-time">
                {english ? "Start Time" : "開工時間"}
              </label>
              <input type="time" onChange={handleStartTime} name="start-time" />
            </div>
            <div className="row-item">
              <label htmlFor="end-time">
                {english ? "End Time" : "完工時間"}
              </label>
              <input type="time" onChange={handleEndTime} name="end-time" />
            </div>
            <div className="row-item">
              <label htmlFor="fee">{english ? "Fee" : "薪資"}</label>
              <input type="number" onChange={handleFee} name="fee" />
            </div>
            <div className="row-item">
              {english ? "Language" : "語言"}
              {languages.map((item) => (
                <div className="industry-container flex">
                  <div className="industry">{item}</div>
                  <div className="remove">
                    {" "}
                    <label htmlFor={item} onClick={removeLanguages}>
                      <input type="checkbox" id={item} value={item} />
                      <FontAwesomeIcon icon={faCircleXmark} />
                    </label>
                  </div>
                </div>
              ))}
              <select
                name="preferred-languages"
                id="preferredLanguages"
                onChange={handleLanguages2}
              >
                <option value="" selected disabled>
                  {english ? "Language" : "選擇你識的語言"}
                </option>
                <option value="廣東話">
                  {english ? "Cantonese" : "廣東話"}
                </option>
                <option value="英文">{english ? "English" : "英文"}</option>
                <option value="普通話">
                  {english ? "Mandarin" : "普通話"}
                </option>
              </select>
            </div>

            <div className="row-item">
              <label htmlFor="district">{english ? "District" : "地區"}</label>
              <select
                name="district"
                id="district"
                onChange={handleDistrict}
                defaultValue="default"
              >
                <option value="default" disabled>
                  {english
                    ? "Select your Preferred Location"
                    : "選擇您喜歡的工作地點"}
                </option>
                <option value="離島區">{english ? "Islands" : "離島區"}</option>
                <option value="葵青區">
                  {" "}
                  {english ? "Kwai Tsing" : "葵青區"}
                </option>
                <option value="北區">{english ? "North" : "北區"}</option>
                <option value="西貢區">
                  {english ? "Sai Kung" : "西貢區"}
                </option>
                <option value="沙田區">
                  {english ? "Sha Tin" : "沙田區"}{" "}
                </option>
                <option value="大埔區">{english ? "Tai Po" : "大埔區"}</option>
                <option value="荃灣區">
                  {" "}
                  {english ? "Tsuen Wan" : "荃灣區"}
                </option>
                <option value="屯門區">
                  {english ? "Tuen Mun" : "屯門區"}
                </option>
                <option value="元朗區">
                  {" "}
                  {english ? "Yuen Long" : "元朗區"}
                </option>
                <option value="九龍城區">
                  {english ? "	Kowloon City" : "九龍城區"}
                </option>
                <option value="觀塘區">
                  {english ? "Kwun Tong" : "觀塘區"}
                </option>
                <option value="深水埗區">
                  {english ? "Sham Shui Po" : "深水埗區"}
                </option>
                <option value="黃大仙區">
                  {english ? "Wong Tai Sin" : "黃大仙區"}
                </option>
                <option value="油尖旺區">
                  {english ? "Yau Tsim Mong" : "油尖旺區"}
                </option>
                <option value="中西區">
                  {english ? "Central and Western" : "中西區"}
                </option>
                <option value="東區">{english ? "Eastern" : "東區"}</option>
                <option value="南區">{english ? "Southern" : "南區"}</option>
                <option value="灣仔區">
                  {english ? "Wan Chai" : "灣仔區"}
                </option>
              </select>
            </div>
            <div className="row-item">
              <label htmlFor="location">{english ? "Address" : "地址"}</label>
              <input type="text" onChange={handleAddress} name="location" />
            </div>
            <div className="row-item">
              <label htmlFor="tags">{english ? "Highlights" : "標籤"}</label>
              <div className="input-container">
                <input
                  type="text"
                  onChange={handleTag}
                  name="tags"
                  ref={tagRef}
                />
                <button className="add-tag" onClick={addTag}>
                  {english ? "Add Tag" : "加標籤"}
                </button>
                <div className="tag-container">
                  {tags &&
                    tags.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="tag-item"
                          onClick={() => handleRemoveTag(index)}
                        >
                          {item}
                          <FontAwesomeIcon icon={faXmark} />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="row-item">
              <label htmlFor="vacancy">
                {english ? "Vacancy" : "職位空缺"}
              </label>
              <input type="number" onChange={handleVacancy} name="vacancy" />
            </div>
          </div>

          <button
            className="create-account-btn"
            onMouseEnter={handleSubmit}
            onClick={handleSubmitFinal}
          >
            {english ? "Done" : "完成"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default CreateWorkshopEmployer;
